import Container from "components/Container/Container";
import box1 from "assests/Home/boxes/14.png";
import box2 from "assests/Home/boxes/15.png";

// UI
import { Link } from "react-router-dom";

const Boxes = ({ isGrey }) => {
  const contaxtBoxes = [
    {
      title: "Sourcing Simplified",
      text: "Choose from more than 5000 products and more than 10 brands",
      link: "www.almakaanstore.com",
      icon: box1,
    },
    {
      title: (
        <>
          Got a project <br className="hidden sm:block" /> let's talk
        </>
      ),
      text: "",
      link: "www.google.com",
      icon: box2,
    },
  ];

  return (
    <section
      className={`py-secondary lg:py-primary ${isGrey ? "bg-grey" : ""}`}
    >
      <Container>
        <div className="flex flex-col lg:flex-row gap-10 ">
          {contaxtBoxes?.map(({ title, text, link, icon }, index) => (
            <div
              key={index}
              className={`flex-1 ${
                index === 0 ? "bg-red" : "bg-brown"
              }    flex flex-col-reverse sm:flex-row sm:items-center xl:items-start sm:justify-between sm:text-start  gap-8 rounded-xl py-8 px-8 font-roboto text-white`}
            >
              <div className="lg:w-3/4">
                <h2 className="text-xl ss:text-3xl mb-2">{title}</h2>
                <p className="mb-4">{text}</p>
                {index === 0 ? (
                  <a
                    className="text-sm transition ease-in duration-300 text-[#ddd] hover:text-white hover:underline"
                    target="_blank"
                    without
                    rel="noreferrer"
                    href={` https:${link}`}
                  >
                    {link}
                  </a>
                ) : (
                  <span className=" border border-white px-6 py-2 mt-6 block w-max">
                    <Link
                      to="/contact-us"
                      className="text-sm transition ease-in duration-300 text-[#ddd] hover:text-white hover:underline"
                    >
                      Get in touch
                    </Link>
                  </span>
                )}
              </div>
              <div className="w-1/3 sm:w-1/3 md: lg:w-[30%] lg:mx-auto flex items-center">
                <img src={icon} alt="" className="" />
              </div>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default Boxes;
