import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useLayoutEffect, useRef } from "react";
import Container from "components/Container/Container";
import "./style.css";

gsap.registerPlugin(ScrollTrigger);

const AboutHero = ({ data = [] }) => {
  const component = useRef();
  const slider = useRef();
  useLayoutEffect(() => {
    if (window.innerWidth > 992) {
      let ctx = gsap.context(() => {
        let panels = gsap.utils.toArray(".panel");

        gsap.to(panels, {
          xPercent: -100 * (panels.length - 1),

          ease: "none",
          scrollTrigger: {
            trigger: slider.current,
            pin: true,
            scrub: 0.01,
            // snap: 0.02,
            snap: 0.2,
            end: "+=2000",
          },
        });
      }, component);

      return () => ctx.revert();
    }
  });

  return (
    <section ref={component} className="hidden lg:block slider-parent ">
      <div className="slider-container  " ref={slider}>
        {data?.map(({ image, title, text }, index) => (
          <div
            key={index}
            className="flex h-[100vh] items-center gap-x- panel-background about-panel panel"
            style={{ backgroundImage: `url(${image})` }}
          >
            <Container>
              <div className="w-1/2">
                <h2 className="text-4xl lg:text-5xl lg:leading-snug  text-lightFont font-philo mb-3">
                  {title}
                </h2>
                <p
                  className="font-philo text-xl text-darkFont"
                  dangerouslySetInnerHTML={{ __html: text }}
                />
              </div>
            </Container>
          </div>
        ))}
      </div>
    </section>
  );
};

export default AboutHero;
