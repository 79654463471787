import { Link } from "react-router-dom";

const BrandFullSection = ({ title, data = [] }) => {
  return (
    <div className="mt-secondary lg:mt-primary">
      <h1 className="text-2xl md:text-3xl text-darkFont font-roboto capitalize mb-4">
        {title}
      </h1>

      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 sm:gap-6 md:gap-8">
        {data?.map((brand, index) => (
          <Link to={`/brand/${brand.slug}`} key={index}>
            <div className="flex flex-col group">
              <div className="flex-1 border transition ease-in duration-300 hover:border-black p-6 rounded-2xl aspect-square ">
                <img
                  src={brand.image}
                  className="w-full h-full object-contain transition ease-in duration-300 group-hover:scale-[1.05]"
                  alt=""
                />
              </div>
              <div className="flex-1">
                <p className="font-roboto text-darkFont font-medium mt-2 group-hover:underline">
                  {brand.title}
                </p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default BrandFullSection;
